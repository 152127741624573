header {
    margin-bottom: 35px;
}

.navbar-custom {
    background-color: #004666;
}

.navbar-custom .navbar-brand,
.navbar-custom .nav-link {
    color: white;
}